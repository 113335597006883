function get_popup_purchase(product_id) {
	$.ajax({
		url: 'index.php?route=module/popup_purchase&product_id='+product_id,
		type: 'post',
		success: function(response) {
			// Add response in Modal body
			$('#modal_container_fastorder .modal-body').html(response);
			$('#modal_container_fastorder').modal();
			$("#popup_purchase_telephone").mask("+7 999 999 99 99");
			popup_purchase_buy();
		}
	});
};


function popup_purchase_buy(){
	$('#popup-checkout-button').click(function() {
		//masked('#popup-purchase-wrapper', true);
		$.ajax({
			type: 'post',
			url:  'index.php?route=module/popup_purchase/make_order',
			dataType: 'json',
			data: $('#purchase-form').serialize(),
			success: function(json) {


				if (json['error']) {
					if (json['error']['field']) {
						//masked('#popup-purchase-wrapper', false);
						$('#popup-purchase-wrapper .text-danger').remove();
						$('#popup-purchase-wrapper .payment-info > div').removeClass('error');
						$.each(json['error']['field'], function(i, val) {
							$('#popup-purchase-wrapper [name="' + i + '"]').parent().addClass('error');
							$('#popup-purchase-wrapper [name="' + i + '"]').addClass('error_style').after('<div class="text-danger">' + val + '</div>');
						});
					}

					if (json['error']['option']) {
						for (i in json['error']['option']) {
							//masked('#popup-purchase-wrapper', false);
							$('#popup-purchase-wrapper .required .text-danger').remove();
							var element = $('#popup-purchase-wrapper #input-option' + i.replace('_', '-'));
							element.after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
						}
					}

					if (json['error']['recurring']) {
						$('#popup-purchase-wrapper select[name=\'recurring_id\']').after('<div class="text-danger">' + json['error']['recurring'] + '</div>');
					}

				} else {
					if (json['output']) {
						//masked('#popup-purchase-wrapper', false);
						$('#popup-checkout-button').remove();
						$('#modal_container_fastorder .modal-header .title').html(json['output_title']);
						$('#popup-purchase-wrapper .popup-center').html(json['output']);
					}
				}
			}
		});
	});

}




function roundPlus(x, n) { //x - число, n - количество знаков
	if(isNaN(x) || isNaN(n)) return false;
	var m = Math.pow(10,n);
	return Math.round(x*m)/m;
}



function getURLVar(key) {
	var value = [];

	var query = String(document.location).split('?');

	if (query[1]) {
		var part = query[1].split('&');

		for (i = 0; i < part.length; i++) {
			var data = part[i].split('=');

			if (data[0] && data[1]) {
				value[data[0]] = data[1];
			}
		}

		if (value[key]) {
			return value[key];
		} else {
			return '';
		}
	}
}

function customSelectInit(){
	//console.log('customSelectInit');
	$(".custom-select select").each(function() {
		if(!$(this).hasClass('is-customized')) {
			$(this).addClass('is-customized');
			var sb = new SelectBox({
				selectbox: $(this),
				customScrollbar: true,
				height: 300,
				changeCallback: function() {
					//alert("I was changed!");
				}

			});
		};
	});
}

function onlyDigit(){
	//console.log('only_digit');
	$("input.onlyDigit").keypress(function(e) { //if the letter is not digit then display error and don't type anything
		if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
			return false
		}
	});
}



/***************************************************************************************/
$(document).ready(function() {


	if(Modernizr.mq('(max-width: 767px)')) {

		var last_position = 0;
		$(window).scroll(function(event){

			if ($(this).scrollTop() > 100) {

				var current_pos = $(this).scrollTop();

				if (current_pos > last_position) {
					$('header').addClass('hid');
					//$('.maincontent').addClass('hid');
					$('.sub_categories_container').addClass('fixed');
				} else {
					$('header').removeClass('hid');
					//$('.maincontent').removeClass('hid');
					$('.sub_categories_container').removeClass('fixed');
				}
				last_position = current_pos;

			}
		});

		/*$(document).swipe( {
			//Generic swipe handler for all directions
			swipe:function(event, direction, distance, duration, fingerCount, fingerData) {

				if(direction == 'down'){
					console.log('down');
				}

				if(direction == 'up'){
					console.log('up');
				}
			},
			//Default is 75px, set to 0 for demo so any distance triggers swipe
			threshold:0
		});*/
	}

	$('.filter_close_buttons button.filter_close_filter').click(function(){
		$('.mfilter-free-button').trigger("click");
	});

	setTimeout(function(){
		var menu = $('.main_menu_block');

		menu.find('li.first_level > a').each(function () {
			if ($(location).attr('href').indexOf($(this).attr('href')) >= 0){
				$(this).parent().addClass('active');
				return;
			}
		});
	}, 1500);

	setTimeout(function(){
		var menu = $('.sub_categories');

		menu.find('a').each(function () {
			if ($(location).attr('href') == $(this).attr('href')){
				$(this).addClass('active');
				return;
			}
		});
	}, 1000);



	/*Partners page init feedback_form*/


	$('button.become_a_partner').click(function(){
		$('#feedbackFormContainer #feedback_form .custom-select-subject .selectList .itm-1').trigger('click');
		$('#overlay').addClass('visible');
		$('#feedbackFormContainer').addClass('open');
	});

	$('button.register_project').click(function(){
		$('#feedbackFormContainer #feedback_form .custom-select-subject .selectList .itm-2').trigger('click');
		$('#overlay').addClass('visible');
		$('#feedbackFormContainer').addClass('open');
	})

	/**/



	onlyDigit();

	customSelectInit();

	/* Feed back form page information-constructor */

	$('.inputfile').each(function() {
		var input = $(this);
		var label = input.next('label');

		var file_upload_container = $(this).parent();
		input.on('change', function(e) {
			var fileName = '';
			if (this.files) {
				fileName = e.target.value.split('\\').pop();
			}
			if (fileName) {
				file_upload_container.find('label').html(fileName);
			} else {
				//text_conteiner.html(default_text);
			}
		});
	})


	/*******************/

	$('body.product-page .product_order_button').click(function(){

		$('#feedbackFormContainer #feedback_form .custom-select-subject .selectList .itm-4').trigger('click');

		var product_name = $('.product_top_block .right_col h1').text();
		var product_link = window.location.href;
		var product_message = "<a href="+product_link+">" + product_name + "</a>";
		var product_message = product_name;

		$('#feedbackFormContainer #feedback_form textarea#feedback_message').html(product_message);

		$('#feedbackFormContainer').addClass('open');
	})

	/*******************/



	$('body.information-constructor button.f_attach_file').click(function(){
		$('#feedbackFormContainer #feedback_form .custom-select-subject .selectList .itm-3').trigger('click');
		$('#feedbackFormContainer #feedback_form .line_file').removeClass('hidden');

		$('#feedbackFormContainer input[type="file"]').trigger('click');
	})


	$('#feedbackFormContainer input[type="file"]').change(function(){
		$('#overlay').addClass('visible');
		$('#feedbackFormContainer').addClass('open');
	});

 	/* Close feedback form */
	$('#feedbackFormContainer .f_close').click(function(){
		$('#overlay').removeClass('visible');
		$('#feedbackFormContainer').removeClass('open');
	});







	/* +- in Cart */

	$('.qty_minus').click(function () {
		var $input = $(this).parent().find('.p_quantity');

		var $input_fingerprint = $input.attr('name');
		var $inputs = $(".custom_quantity input[name*='"+$input_fingerprint+"']");

		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;

		$inputs.val(count);
		//$input.val(count);

		$input.change().edit();
		return false;
	});

	$('.qty_plus').click(function () {
		var $input = $(this).parent().find('.p_quantity');

		var $input_fingerprint = $input.attr('name');
		var $inputs = $(".custom_quantity input[name*='"+$input_fingerprint+"']");

		$inputs.val(parseInt($input.val()) + 1);
		//$input.val(parseInt($input.val()) + 1);

		$input.change().edit();
		return false;
	});





	/*Custom_filter */

	$('.active_filters > label').remove();
	$('.mfilter-input-active').each(function(index, elem){
		if(!$(elem).closest('.mfilter-filter-item').hasClass('mfilter-categories')) {
			$(elem).find('label').clone().prependTo('.active_filters');
		}
	});

	var filters_count = $('.active_filters label').length;
	if(filters_count > 0){
		$('.filters_button .counter').text(filters_count);
		$('.active_filters').addClass('active');
	}else{
		$('.filters_button .counter').text('0');
		$('.active_filters').removeClass('active');
	}


	$('.mfilter-button-reset-clone').click(function(){
		$('a.mfilter-button-reset').trigger('click');
	});
	/*****************/




	if(Modernizr.mq('(min-width: 992px)')) {
		$(window).scroll(function() {
			if ($(this).scrollTop() > 101) {
				$('header').addClass('fixed');
			} else {
				$('header').removeClass('fixed');
			}
		});
	}
	/****************Simpla phone masked*************/
	$(".simplecheckout input[type=tel]").mask("+7 999 999 99 99");

	/****************Main slideshow*****************/
	if ($('#main_slideshow').length) {

		/*$('#main_slideshow .image_col').hover(
			function(){
				$('#main_slideshow .text_col').mousemove(function( event ) {
					var target = event.target.closest('#main_slideshow');
					var targetCoords = target.getBoundingClientRect();
					var currentMousePosX = event.clientX - targetCoords.left;
					var currentMousePosY = event.clientY - targetCoords.top;
					$('#main_slideshow .icon-arrow_right').css({top: currentMousePosY, left: currentMousePosX});
				});

				$('#main_slideshow .image_col').mousemove(function( event ) {
					var target = event.target.closest('#main_slideshow');
					var targetCoords = target.getBoundingClientRect();
					var currentMousePosX = event.clientX - targetCoords.left;
					var currentMousePosY = event.clientY - targetCoords.top;
					$('#main_slideshow .icon-arrow_left').css({top: currentMousePosY, left: currentMousePosX});
				});



				$('#main_slideshow .slick-arrow').removeClass('visible');
				$('#main_slideshow .icon-arrow_left').addClass('visible');
			},
			function(){
				//$('#main_slideshow .icon-arrow_left').removeClass('visible');
			}
		);*/

		$('#main_slideshow .text_col').hover(
			function(){
				$('#main_slideshow .slick-arrow').removeClass('visible');
				$('#main_slideshow .icon-arrow_right').addClass('visible');
			},
			function(){
				//$('#main_slideshow .icon-arrow_right').removeClass('visible');
			}
		);

		$('#main_slideshow').mouseleave(function( event ) {
			$('#main_slideshow .icon-arrow_left').removeClass('visible');
			$('#main_slideshow .icon-arrow_right').removeClass('visible');
		});


		$('#main_slideshow .text_col .readmore_button').hover(
			function(){
				$('#main_slideshow .slick-arrow').css("z-index", "-1");
			},
			function(){
				$('#main_slideshow .slick-arrow').css("z-index", "1");
			}
		);


		$('#main_slideshow').slick({
			arrows: true,
			prevArrow: '<span class="icon icon-arrow_left"></span>',
			nextArrow: '<span class="icon icon-arrow_right"></span>',
			dots: true,
			autoplay: true,
			autoplaySpeed: 40000000,
			centerMode: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
	};


	/*** Categories_accordeon_module on home - page ***/
	$('.accordeon_category_module .categories_content ul li:first-child').addClass('open');


	$('.accordeon_category_module .categories_content ul li').click(function(){
		$('.accordeon_category_module .categories_content ul li.open').removeClass('open');
		$(this).addClass('open');
	});

	/*if(Modernizr.mq('(min-width: 1367px)')) {
		$('.accordeon_category_module .categories_content ul li.with_sub_menu .ico').mouseover(function(){
			if($(this).closest('.with_sub_menu').hasClass('open')){
				$(this).closest('.with_sub_menu').removeClass('open');
			};
		});
	};*/


	/*******************footer mobile categories accordeon ******************/

	//$('.mobile_footer_container .categories_block ul li:first-child').addClass('open');

	$('.mobile_footer_container .categories_block ul li').click(function(){
		//$('.mobile_footer_container .categories_block ul li.open').removeClass('open');
		$(this).toggleClass('open');
	})


	/****************TOP 10 Products module**********************************/
	if($('.tops_slider').length){
		$('.tops_slider').slick({
			dots: true,
			arrows: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			autoplay: false,
			autoplaySpeed: 4000,
			prevArrow: '<span class="icon icon-arrow_left"></span>',
			nextArrow: '<span class="icon icon-arrow_right"></span>',
			responsive: [
				{
					breakpoint: 992,
					settings: {
						dots: false,
						arrows: false,
						slidesToShow: 2,
						centerMode: true,
						centerPadding: '60px',
						//variableWidth: true
					}
				},
				{
					breakpoint: 767,
					settings: {
						dots: false,
						arrows: false,
						slidesToShow: 2,
						centerMode: true,
						//centerPadding: '12px',
						//centerPadding: '40px',
					}
				},

			]
		});
	}

	if(Modernizr.mq('(max-width: 991px)')) {
		if($('.blog_articles_module')) {
			$('.blog_articles_module .box-content .row').slick({
				dots: false,
				arrows: false,
				slidesToShow: 2,
				slidesToScroll: 1,
				autoplay: false,
				autoplaySpeed: 4000,
				centerMode: true,
				//centerPadding: '30px',
				responsive: [
					{
						breakpoint: 767,
						settings: {
							dots: false,
							arrows: false,
							slidesToShow: 1,
							centerMode: true,
							//centerPadding: '12px',
							//centerPadding: '40px',
						}
					},

				]
			})
		}



		if($('.related_articles')) {
			$('.related_articles .articles').slick({
				dots: false,
				arrows: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				autoplay: false,
				autoplaySpeed: 4000,
				centerMode: true,
				//centerPadding: '30px',
				responsive: [
					{
						breakpoint: 767,
						settings: {
							dots: false,
							arrows: false,
							slidesToShow: 1,
							centerMode: true,
							//centerPadding: '12px',
							//centerPadding: '40px',
						}
					},

				]
			})
		}


	}


/**************************************************************************/
	$('header .hamburger').click(function(){
		$('#overlay').toggleClass('visible');
		$('body').toggleClass('overflow_hidden');
		$(this).toggleClass('open');
		$('#mobile_menu').toggleClass('open');
		$('#mobile_menu ul li.with_sub_menu.open').removeClass('open');
	})

	$('#mobile_menu .with_sub_menu .icon').click(function(){
		$('#mobile_menu ul li.with_sub_menu.open').removeClass('open');
		$(this).closest('.with_sub_menu').addClass('open');
	})

	$('#mobile_menu .sub_menu_container .cat_menu_container .cat_title .icon').click(function(){
		$(this).closest('.with_sub_menu').removeClass('open');
	})

	/*******************/

	$('.filters_button').click(function(){
		$('.mfilter-free-container').addClass('open');
		$('#overlay').addClass('visible_filter');
	});

	$('.mfilter-free-button').click(function(){
		$('.mfilter-free-container').removeClass('open');
		$('#overlay').removeClass('visible_filter');
	});

/**************************************************************************/
	if ($('.product-big-image.gallery').length){
		$('.product-big-image.gallery').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			dots: false,
			arrows: true,
			prevArrow: '<span class="icon icon-arrow_left"></span>',
			nextArrow: '<span class="icon icon-arrow_right"></span>',
		});

		$('.product-big-image.gallery a').magnificPopup({
			type:'image',
			gallery: {
				enabled: true
			}
		});

	};


	if ($('.product-big-image').length){
		$('.product-big-image a.popup').magnificPopup({
			type:'image',
			gallery: {
				enabled: false
			}
		});
	}
/**************************************************************************************/
	if($('.product_page_sliders').length){
		$('.product_page_sliders').slick({
			dots: true,
			arrows: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 4000,
			prevArrow: '<span class="icon icon-arrow_left"></span>',
			nextArrow: '<span class="icon icon-arrow_right"></span>',
			responsive: [
				{
					breakpoint: 992,
					settings: {
						dots: false,
						arrows: false,
						slidesToShow: 2,
						centerMode: true,
						centerPadding: '60px',
						//variableWidth: true
					}
				},
				{
					breakpoint: 767,
					settings: {
						dots: false,
						arrows: false,
						slidesToShow: 2,
						centerMode: true,
						//centerPadding: '12px',
						//centerPadding: '40px',
					}
				},
			]
		});
	}
/**************************************************************************************/
	/*Open*/
	$('header .search > label.title').click(function(){
		$('body').toggleClass('overflow_hidden');
		$('.cart_search_block .search .input_container').toggleClass('open');
		$('.wrapp_ajax_container').toggleClass('open');
	});
	$('header .search > button.search_button').click(function(){
		$('body').toggleClass('overflow_hidden');
		$('.cart_search_block .search .input_container').toggleClass('open');
		$('.wrapp_ajax_container').toggleClass('open');
	});
	/*Close*/
	$('header .search .input_container .icon-close_search').click(function(){
		$('header .search .input_container input').val('');

		$(".wrapp_ajax_container").removeClass("with_products");
		$(".wrapp_ajax_container .container").removeClass("with_products");
		$(".wrapp_ajax_container").removeClass("no_products");
		$(".wrapp_ajax_container .container").removeClass("no_products");

		var $palceholder = $('#ajax_search_placeholder').html();
		$(".ajax_search_results").html($palceholder);

		$('body').removeClass('overflow_hidden');
		$('.cart_search_block .search .input_container').removeClass('open');
		$('.wrapp_ajax_container').removeClass('open');
	})
	/*******************/
	$("body").on("click", ".see_all_ajax", function() {

		var search = $('.search input[name=\'search\']').prop('value');

		url = 'index.php?route=product/search';

		if (search) {
			url += '&search=' + encodeURIComponent(search);
		}
		location = url;
	});




	/* load ajax */ /* search */
	$(document).on("keyup", ".search input[name=\"search\"]", function(e) {

		var search = $(this).val();
		if (search.length > 2) {
			//$("body").addClass("overlay_active");
			$.ajax({
				url: "index.php?route=product/search/autocomplete",
				type: "post",
				data: "filter_name=" + search,
				dataType: "json",
				success: function success(json) {

					$(".ajax_search_results").html('');
					$(".wrapp_ajax_container").removeClass("with_products");
					$(".wrapp_ajax_container .container").removeClass("with_products");
					$(".wrapp_ajax_container").removeClass("no_products");
					$(".wrapp_ajax_container .container").removeClass("no_products");

					if(json['total_products'] > 0){
						$(".wrapp_ajax_container").addClass("with_products");
						$(".wrapp_ajax_container .container").addClass("with_products");
					}else{
						$(".wrapp_ajax_container").addClass("no_products");
						$(".wrapp_ajax_container .container").addClass("no_products");
					}


					$(".wrapp_ajax_container .container .ajax_search_results").append(json["html"]);


					/*$('[data-countdown]').each(function() {
						var $this = $(this), finalDate = $(this).data('countdown');
						$this.countdown(finalDate, function(event) {
							$this.html(event.strftime('%D:%H:%M:%S'));
						});
					});*/

				},
				error: function error(xhr, ajaxOptions, thrownError) {
					alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
				}
			})
		} else {
			/*$(".wrapp_ajax_container").css({
				"height": 0
			});
			$(".ajax_search_results").remove();
			$("body").removeClass("overlay_active")*/
		}
	});
	/* search */


/**************************************************************************************/
	$('#mobile_button_cart').click(function(){
		$('#product .buy_button').trigger('click');
	});

	// Add product to cart  in product card page;
	$(document).on('click', '#product .buy_button', function () {
		$.ajax({
			url: 'index.php?route=checkout/cart/add',
			type: 'post',
			data: $('#product input[type=\'text\'], #product input[type=\'hidden\'], #product input[type=\'radio\']:checked, #product input[type=\'checkbox\']:checked, #product select, #product textarea'),
			dataType: 'json',
			beforeSend: function() {
				//$('#button-cart').button('loading');
			},
			complete: function() {
				//$('#button-cart').button('reset');
			},
			success: function(json) {
				//$('.alert, .text-danger').remove();
				$('.options_container .text-danger').remove();

				if (json['error']) {
					if (json['error']['option']) {
						for (i in json['error']['option']) {
							var element = $('#input-option' + i.replace('_', '-'));

							if (element.parent().hasClass('input-group')) {
								element.parent().after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
							} else {
								element.after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
							}
						}
					}

					if (json['error']['recurring']) {
						$('select[name=\'recurring_id\']').after('<div class="text-danger">' + json['error']['recurring'] + '</div>');
					}

					// Highlight any found errors

				}

				if (json['success']) {

					setTimeout(function () {
						$('#info_popup_addcart .text .content').html(json['product']);
						$('.cart > button .cart-total').html(json['total']);
						$('.cart').addClass('active');
					}, 100);

					$('#info_popup_addcart').addClass('open');

					setTimeout(function () {
						$('#info_popup_addcart .text .content').html('');
						$('#info_popup_addcart').removeClass('open');
					}, 3000);

				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});

	});
});
/**************************************************************************/

	// Cart add remove functions
	var cart = {
		'add': function (product_id, quantity) {
			$.ajax({
				url: 'index.php?route=checkout/cart/add',
				type: 'post',
				data: 'product_id=' + product_id + '&quantity=' + (typeof (quantity) != 'undefined' ? quantity : 1),
				dataType: 'json',
				beforeSend: function () {
					//$('#cart > button').button('loading');
				},
				complete: function () {
					$('.cart > button').button('reset');
				},
				success: function (json) {
					$('.alert, .text-danger').remove();

					if (json['redirect']) {
						location = json['redirect'];
					}

					if (json['success']) {

						//$('#content').parent().before('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');

						// Need to set timeout otherwise it wont update the total
						setTimeout(function () {
							$('#info_popup_addcart .right_col .text .content').html(json['product']);
							$('.cart > button').html('<i class="icon icon-icon_cart ani"></i><span class="cart-total">' + json['total'] + '</span>');
						}, 100);

						//$('html, body').animate({ scrollTop: 0 }, 'slow');

						$('#info_popup_addcart').addClass('open');

						setTimeout(function () {
							$('#info_popup_addcart .right_col .text .content').html('');
							$('#info_popup_addcart').removeClass('open');
						}, 3000);

						$('.cart .cart__content > ul').load('index.php?route=common/cart/info ul li');
					}
				},
				error: function (xhr, ajaxOptions, thrownError) {
					alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},
		'update': function (key, quantity) {
			$.ajax({
				url: 'index.php?route=checkout/cart/edit',
				type: 'post',
				data: 'key=' + key + '&quantity=' + (typeof (quantity) != 'undefined' ? quantity : 1),
				dataType: 'json',
				beforeSend: function () {
					//$('#cart > button').button('loading');
				},
				complete: function () {
					$('.cart > button').button('reset');
				},
				success: function (json) {
					// Need to set timeout otherwise it wont update the total
					setTimeout(function () {
						$('.cart > button').html('<i class="icon icon-icon_cart ani"></i><span class="cart-total">' + json['total'] + '</span>');
					}, 100);

					if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
						location = 'index.php?route=checkout/cart';
					} else {
						$('.cart .cart__content > ul').load('index.php?route=common/cart/info ul li');
					}
				},
				error: function (xhr, ajaxOptions, thrownError) {
					alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},
		'remove': function (key) {
			console.log('remove');
			$.ajax({
				url: 'index.php?route=checkout/cart/remove',
				type: 'post',
				data: 'key=' + key,
				dataType: 'json',
				beforeSend: function () {
					$('.cart > button').button('loading');
				},
				complete: function () {
					$('.cart > button').button('reset');
				},
				success: function (json) {
					// Need to set timeout otherwise it wont update the total
					setTimeout(function () {
						$('.cart > button .cart-total').html(json['total']);
					}, 100);

					setTimeout(function () {
						location = 'index.php?route=checkout/cart';
					},300);

				},
				error: function (xhr, ajaxOptions, thrownError) {
					alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},

		'delete': function (key,name) {
			$("#deleteModal #product_name").text(name);
			var click_func = $("#deleteModal .delete_button").attr("onclick");
			var func_name = click_func.substring(0,click_func.indexOf("("));
			$("#deleteModal .delete_button").attr("onclick",func_name+"("+key+")");

			$("#deleteModal").modal();
		},
		'no_delete': function () {
			var click_func = $("#deleteModal .delete_button").attr("onclick");
			var func_name = click_func.substring(0,click_func.indexOf("("));

			$("#deleteModal #product_name").text('');
			$("#deleteModal .delete_button").attr("onclick",func_name+"()");
			$("#deleteModal").modal('hide');
		}
	}

	var voucher = {
		'add': function () {

		},
		'remove': function (key) {
			$.ajax({
				url: 'index.php?route=checkout/cart/remove',
				type: 'post',
				data: 'key=' + key,
				dataType: 'json',
				beforeSend: function () {
					//$('#cart > button').button('loading');
				},
				complete: function () {
					$('.cart > button').button('reset');
				},
				success: function (json) {
					// Need to set timeout otherwise it wont update the total
					setTimeout(function () {
						$('.cart > button').html('<i class="icon icon-icon_cart ani"></i><span class="cart-total">' + json['total'] + '</span>');
					}, 100);

					if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
						location = 'index.php?route=checkout/cart';
					} else {
						$('.cart > ul').load('index.php?route=common/cart/info ul li');
					}
				},
				error: function (xhr, ajaxOptions, thrownError) {
					alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		}
	}

	var wishlist = {
		'add': function (product_id) {
			$.ajax({
				url: 'index.php?route=account/wishlist/add',
				type: 'post',
				data: 'product_id=' + product_id,
				dataType: 'json',
				success: function (json) {
					$('.alert').remove();

					if (json['redirect']) {
						location = json['redirect'];
					}

					if (json['success']) {
						//$('#content').parent().before('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');


						var list, index;
						list = document.getElementsByClassName('wishlist-' + product_id);
						for (index = 0; index < list.length; ++index) {
							list[index].setAttribute('disabled', 'disabled');
						}

						$('.header__wishlist_container .wishlist-total-counter').html(json['total']);
						$('.header__wishlist_container .wishlist-total-counter').addClass('active');

						$('#info_popup_wishlist').addClass('open');

						setTimeout(function () {
							$('#info_popup_wishlist').removeClass('open');
						}, 2000);

						//$('html, body').animate({ scrollTop: 0 }, 'slow');


					}


				},
				error: function (xhr, ajaxOptions, thrownError) {
					alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},
		'remove': function () {
			$('#deleteModal').modal();

		}
	}

	var compare = {
		'add': function (product_id) {
			$.ajax({
				url: 'index.php?route=product/compare/add',
				type: 'post',
				data: 'product_id=' + product_id,
				dataType: 'json',
				success: function (json) {
					$('.alert').remove();

					if (json['success']) {
						//$('#content').parent().before('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');


						var list, index;
						list = document.getElementsByClassName('compare-' + product_id);
						for (index = 0; index < list.length; ++index) {
							list[index].setAttribute('disabled', 'disabled');
						}


						$('.header__compare_container .compare-total').html(json['total']);
						$('.header__compare_container .compare-total').addClass('active');
						$('#info_popup_compare').addClass('open');

						setTimeout(function () {
							$('#info_popup_compare').removeClass('open');
						}, 2000);


						//$('html, body').animate({ scrollTop: 0 }, 'slow');
					}
				},
				error: function (xhr, ajaxOptions, thrownError) {
					alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},
		'remove': function () {

		}
	}

	/* Agree to Terms */
	$(document).delegate('.agree', 'click', function (e) {
		e.preventDefault();

		$('#modal-agree').remove();

		var element = this;

		$.ajax({
			url: $(element).attr('href'),
			type: 'get',
			dataType: 'html',
			success: function (data) {
				html = '<div id="modal-agree" class="modal">';
				html += '  <div class="modal-dialog modal-dialog-centered">';
				html += '    <div class="modal-content">';
				html += '      <div class="modal-header">';
				html += '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
					'                    <span aria-hidden="true" class="icon icon-icon_close_buble_mobile"></span>' +
					'                </button>';
				html += '        <h3 class="modal-title">' + $(element).text() + '</h3>';
				html += '      </div>';
				html += '      <div class="modal-body">' + data + '</div>';
				html += '    </div';
				html += '  </div>';
				html += '</div>';

				$('body').append(html);

				$('#modal-agree').modal('show');
			}
		});
	});

// Autocomplete */
	(function ($) {
		$.fn.autocomplete = function (option) {
			return this.each(function () {
				this.timer = null;
				this.items = new Array();

				$.extend(this, option);

				$(this).attr('autocomplete', 'off');

				// Focus
				$(this).on('focus', function () {
					this.request();
				});

				// Blur
				$(this).on('blur', function () {
					setTimeout(function (object) {
						object.hide();
					}, 200, this);
				});

				// Keydown
				$(this).on('keydown', function (event) {
					switch (event.keyCode) {
						case 27: // escape
							this.hide();
							break;
						default:
							this.request();
							break;
					}
				});

				// Click
				this.click = function (event) {
					event.preventDefault();

					value = $(event.target).parent().attr('data-value');

					if (value && this.items[value]) {
						this.select(this.items[value]);
					}
				}

				// Show
				this.show = function () {
					var pos = $(this).position();

					$(this).siblings('ul.dropdown-menu').css({
						top: pos.top + $(this).outerHeight(),
						left: pos.left
					});

					$(this).siblings('ul.dropdown-menu').show();
				}

				// Hide
				this.hide = function () {
					$(this).siblings('ul.dropdown-menu').hide();
				}

				// Request
				this.request = function () {
					clearTimeout(this.timer);

					this.timer = setTimeout(function (object) {
						object.source($(object).val(), $.proxy(object.response, object));
					}, 200, this);
				}

				// Response
				this.response = function (json) {
					html = '';

					if (json.length) {
						for (i = 0; i < json.length; i++) {
							this.items[json[i]['value']] = json[i];
						}

						for (i = 0; i < json.length; i++) {
							if (!json[i]['category']) {
								html += '<li data-value="' + json[i]['value'] + '"><a href="#">' + json[i]['label'] + '</a></li>';
							}
						}

						// Get all the ones with a categories
						var category = new Array();

						for (i = 0; i < json.length; i++) {
							if (json[i]['category']) {
								if (!category[json[i]['category']]) {
									category[json[i]['category']] = new Array();
									category[json[i]['category']]['name'] = json[i]['category'];
									category[json[i]['category']]['item'] = new Array();
								}

								category[json[i]['category']]['item'].push(json[i]);
							}
						}

						for (i in category) {
							html += '<li class="dropdown-header">' + category[i]['name'] + '</li>';

							for (j = 0; j < category[i]['item'].length; j++) {
								html += '<li data-value="' + category[i]['item'][j]['value'] + '"><a href="#">&nbsp;&nbsp;&nbsp;' + category[i]['item'][j]['label'] + '</a></li>';
							}
						}
					}

					if (html) {
						this.show();
					} else {
						this.hide();
					}

					$(this).siblings('ul.dropdown-menu').html(html);
				}

				$(this).after('<ul class="dropdown-menu"></ul>');
				$(this).siblings('ul.dropdown-menu').delegate('a', 'click', $.proxy(this.click, this));

			});
		}
	})(window.jQuery);




