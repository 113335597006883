function showmorebutton() {
    if ($('.pagination li.active').next('li').find('a.digit').length > 0) {
		var more_button = $('.more_button_text').text();
        $('ul.pagination').before('<button onclick="showmore()" id="showmore" class="reset_button"' + more_button + '</button>');

    }
}

function showmore() {
    var $next = $('.pagination li.active').next('li').find('a.digit');
	
    if ($next.length == 0) {
        return;
    }

    $.get($next.attr('href'), function (data) {
        $data = $(data);		
        var $container = $('.products_row');
		$container.append($data.find('.products_row .item'));

		
        $('.pagination').html($data.find('.pagination > *'));
        if ($('.pagination li.active').next('li').find('a.digit').length == 0) {
            //$('#showmore').prop( "disabled", true );
            $('#showmore').hide();
        }

        countdown_init();

		/*$data.filter('script').each(function () {
            if ((this.text || this.textContent || this.innerHTML).indexOf("document.write") >= 0) {
                return;
            }
            $.globalEval(this.text || this.textContent || this.innerHTML || '');
        });*/
        //$('html, body').animate({ scrollTop: $container.offset().top - 10 }, 'slow');

    }, "html");
    return false;

}
