function cong_feedback() {

    $('#feedback_form').ajaxSubmit();

    setTimeout(function() {
        $('#overlay').removeClass('visible');
        $('#feedbackFormContainer').removeClass('open');
    }, 700);

    setTimeout(function() {
        location = '/index.php?route=common/feedback_success';
    }, 1400);

    return true;
}


$(document).ready(function() {
    $("#feedback_form .phone").mask("+7 999 999 99 99");

    $.validator.addMethod("valueNotEquals", function(value, element, arg){
        return arg !== value;
    }, "Value must not equal arg.");

    $("#feedback_form").validate({

        submitHandler: function() {
            cong_feedback();
        },

        highlight: function(element, errorClass) {
            $(element).parent().addClass("error");
        },
        success: function(element) {
            $(element).parent().removeClass("error");
        },

        rules: {
            subject:{
                valueNotEquals: "default",
            },
            fio: {
                required: true
            },
            company: {
                required: true
            },
            position: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                required: true,
            },
            message: {
                required: true
            },

        },
        messages: {
            subject: {
                valueNotEquals: "<span class='lang ru'>Сделайте свой выбор!</span>",
            },
            fio: {
                required: "<span class='lang ru'>Введите ФИО!</span>"
            },
            company: {
                required: "<span class='lang ru'>Введите название компании!</span>"
            },
            position: {
                required: "<span class='lang ru'>Введите должность!</span>"
            },
            email: {
                required: "<span class='lang ru'>Введите email!</span>",
                email: "<span class='lang ru'>Некорректний email!</span>",
            },
            phone: {
                required: "<span class='lang ru'>Введите номер телефона!</span>",
            },
            message: {
                required: "<span class='lang ru'>Введите текст сообщения!</span>"
            },

        },
    });
});