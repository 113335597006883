$('.tender_file').each(function(){
	var input	              = $(this);
	var	label	              = input.next('label');

	input.on('change', function(e){
		if ($(this).val()) {

			var fileName = '';
			if (this.files) {
				fileName = e.target.value.split('\\').pop();
				fileSize = '(' + roundPlus((this.files[0].size) / 1000, 2) + 'K)';
			}

			if (fileName) {
				$('.label_tender_documentation').hide();
				$('.attached_file_info').addClass('visible');
				$('.attached_file_info .file_name').text(fileName);
				$('.attached_file_info .file_size').text(fileSize);
			}
		}else{
			$('.label_tender_documentation').show();
			$('.attached_file_info').removeClass('visible');
			$('.attached_file_info .file_name').text('');
			$('.attached_file_info .file_size').text('');
		}

	});
});


$('#invite_tender .attached_file_info .remove').click(function(){
	$(this).closest('.custom_input_file').find('#tender_documentation').val('');
	$(this).closest('.custom_input_file').find('#tender_documentation').trigger('change');
})

/*****************************************************/
	function cong_tenders(){
		jQuery('#invite_tender').ajaxSubmit();

		setTimeout(function() {
			location = '/index.php?route=common/tender_success';
		}, 1400);

		return true;
	}
	
	jQuery("#invite_tender").validate({
		submitHandler:function() {
			cong_tenders();
		},

		rules: {
			name: {
				required: true,
			},
			company: {
				required: true,
			},
			message: {
				required: true,
			},
		},
		messages: {
			name: {
				required: "Введите имя!",
			},
			company: {
				required: "Введите название компании!",
			},
			message: {
				required: "Введите текст сообщения!",
			}
		},
	});
	
	
	
	
